<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="information" service="mortgage" firstStep backlink="/mortgage/registration" />
      <div class="content">
        <div class="content__container mobile_center">
          <p class="promo promo_blocks2">{{ "s2p5_text" | localize }}</p>
          <div class="service__container">
            <router-link
              v-for="n in 3"
              :key="n"
              :to="{ name: 'Mortgage client', params: { clients: n } }"
              :class="'service service_members service_members_' + n"
            >
              <div class="service__title">
                {{ ("s2p5_block_" + n + "_title") | localize }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'

export default {
  name: 'Mortgage_members',
  components: { Backlink }
}
</script>
